<template>
  <div class="wrapper">
    <!-- 车型-->
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="名称">
          <el-input placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <el-divider></el-divider>
    <!-- <div class="header-btn">
      <el-button @click="$router.push({ name: 'CarModelAdd' })" type="primary" icon="el-icon-plus" size="small">新增</el-button>
    </div> -->
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getCarListAPI } from '../api'
const columns = [
  {
    label: '车牌号',
    prop: 'license_plate',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['license_plate']}</p>
    }
  },

  {
    label: '品牌',
    prop: 'car_brand',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['car_brand']?.['name']}</p>
    }
  },
  {
    label: '车型',
    prop: 'car_model',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['car_model']?.['name']}</p>
    }
  }
]
export default {
  name: 'UserCarList',

  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      let params = { page: this.currentPage, pageSize: this.pageSize, uid: this.$route.params.id }
      const res = await getCarListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    }


  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
