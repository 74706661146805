import { http } from '@/http/axios.js'
// 用户列表
export function getListAPI(params) {
  return http({
    url: '/company/basic/user/list',
    method: 'get',
    params
  })
}

// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/basic/user/detail',
    method: 'get',
    params: { id }
  })
}
// 用户车辆列表
export function getCarListAPI(params) {
  return http({
    url: '/company/userCar/list',
    method: 'get',
    params
  })
}
// 用户流水列表
export function getBalanceListAPI(params) {
  return http({
    url: '/company/balanceFlow/list',
    method: 'get',
    params
  })
}
